<template>
  <div class="addBox">
    <!--  面包屑  -->
    <div class="mrj-layout-tabs-custom flex align-item-center" style="z-index:1;">
      <div @click="changeRoute" class="mrj-layout-tabs-custom-index flex align-item-center cursor-pointer">
        <div type="left" class="leftarrow" :class="imgflag?'leftarrow_hover':''" @mouseenter="imgflag=true" @mouseleave="imgflag=false">
          <i class="meiye-icon meiye-fanghui"></i>
        </div>
        <span @mouseenter="imgflag=true" @mouseleave="imgflag=false">{{ $route.meta.title }}</span>
      </div>
      <span class="mrj-layout-tabs-custom-fenge">/</span>
      <span class="mrj-layout-tabs-custom-inner-page">{{$route.query.type == 1?'新建合伙人方案':$route.query.type == 2?"编辑":"查看" }}</span>
    </div>
    <!-- 内容区 -->
    <div class="flex flex-column">
      <a-layout class="height100 flex">
        <TagsSider :tabs="config.tabs" v-model="activeKey" />
        <a-layout-content class="main-ctn-2" id="mrj-scroll-main" @scroll="handleWrapScroll">
          <div id="product-base" data-anchor="product-base">
            <h2 class="twoh2">
              <span class="tpointer"></span>
              <span class="ttxt">基本配置</span>
            </h2>
            <a-row>
              <a-col :span="4">
                <div class="left-title">合伙人方案ID</div>
              </a-col>
              <a-col :span="16">
                <span class="right-title">{{form.scheme_identifier}}</span>
              </a-col>
            </a-row>
            <a-row>
              <a-col :span="4">
                <div class="left-title">方案名称 </div>
              </a-col>
              <a-col :span="16" class="ant-input_1">
                <span class="right-title">{{form.scheme_name}}</span>
              </a-col>
            </a-row>
            <a-row>
              <a-col :span="4">
                <div class="left-title">合伙人申请入口开放时间
                  <a-tooltip placement="top">
                    <template slot="title">
                      合伙人开始缴纳认购金,开放时间内门店可对合伙人进行录入。
                    </template>
                    <a-icon :style="{ color: '#819190' }" type="info-circle" />
                  </a-tooltip>
                </div>
              </a-col>
              <a-col :span="16">
                <span class="right-title">{{form.application_start_date || '--'}}~{{form.application_end_date || '--'}}</span>
              </a-col>
            </a-row>
            <a-row>
              <a-col :span="4">
                <div class="left-title">合伙人身份结束时间
                  <a-tooltip placement="top">
                    <template slot="title">
                      合伙人身份结束后,不再享受: <br>
                      1、转介绍客户消费所产生的佣金收益;<br>
                      2、合伙人权益。
                    </template>
                    <a-icon :style="{ color: '#819190' }" type="info-circle" />
                  </a-tooltip>
                </div>
              </a-col>
              <a-col :span="16">
                <span class="right-title">{{form.identify_end_date || '--'}}</span>
              </a-col>
            </a-row>
            <a-row>
              <a-col :span="4">
                <div class="left-title">分红统计年度
                  <a-tooltip placement="right" overlayClassName="spec_tooltip">
                    <template slot="title">
                      设置分红统计时间段:<br>
                      1、总部可生成授权门店所属该时间段的门店分红订单;<br>
                      2、门店可生成所属该时间段的合伙人分红订单, 在结算周期内可对合伙人分红订单进行结算。
                    </template>
                    <a-icon :style="{ color: '#819190' }" type="info-circle" />
                  </a-tooltip>
                </div>
              </a-col>
              <a-col :span="16">
                <span class="right-title">{{form.dividend_ratio_year || '--'}} 年</span>
              </a-col>
            </a-row>
          </div>
          <div id="product-detail" data-anchor="product-detail">
            <h2 class="twoh2">
              <span class="tpointer"></span>
              <span class="ttxt">收益配置</span>
            </h2>
            <div class="mgl-12">
              <!-- 认购金 -->
              <h2 class="twoh1">
                <span class="tpointer"></span>
                <span class="ttxt">认购金<span class="ttxtspan">成为合伙人需要购买的门店消费股</span></span>
              </h2>
              <a-row>
                <a-col :span="5">
                  <div class="textColor1">消费股价格</div>
                  <span class="font-22" v-if="$route.query.type == 3">{{form.share_price}}</span>
                  <a-input-number style="width: 178px;" size="large" v-else v-model="form.share_price" id="inputNumber" :min="0" /> <span style="font-size: 14px;font-weight: 400;color: #A4B3B1;"> 元 / 股</span>
                </a-col>
                <a-col :span="5">
                  <div class="textColor1">起购股数</div>
                  <span class="font-22" v-if="$route.query.type == 3">{{form.min_shares}}</span>
                  <a-input-number style="width: 178px;" size="large" v-else v-model="form.min_shares" id="inputNumber" :min="0" /> <span style="font-size: 14px;font-weight: 400;color: #A4B3B1;">股</span>
                </a-col>
                <a-col :span="5">
                  <div class="textColor1">溢价比例</div>
                  <span class="font-22" v-if="$route.query.type == 3">{{form.premium_rate}}</span>
                  <a-input-number style="width: 178px;" size="large" v-else v-model="form.premium_rate" id="inputNumber" :min="0" :max="100" /> <span style="font-size: 14px;font-weight: 400;color: #A4B3B1;">%</span>
                </a-col>
              </a-row>
              <!-- <p class="textSmall"><a-icon type="info-circle" theme="filled" :style="{ color: '#98B9B7' }" /> 最低认购价 30000元 产生认购金35000元 </p> -->
              <!-- 营业分红 -->
              <h2 class="twoh1 mgt-40">
                <span class="tpointer"></span>
                <span class="ttxt">营业分红<span class="ttxtspan">合伙人期间享受门店营业额业绩效益的年度分红
                    <a-tooltip placement="top">
                      <template slot="title">
                        基础业绩(收现):卡项（ 包括服务、 项目、 套盒、 权益卡） 产品的收款总额（ 排除合作项目， 收款方式不含卡扣）<br>
                        合作项目(收现):合作项目的收款总额（收款方式不含卡扣）<br>
                        产品业绩(收现):实物产品的收款总额（排除合作项目，收款方式不含卡扣）<br>
                      </template>
                      <a-icon :style="{ color: '#819190' }" type="info-circle" />
                    </a-tooltip>
                  </span></span>
              </h2>
              <a-table class="mgb-12 mgt-10 detailsTable" style="width:99.9%" :pagination="false" row-key="id" size="middle" :loading="loading" :columns="config.add.columns_1" :data-source="form.dividend_ratio_list">
                <template slot="_0" slot-scope="text, record, index">
                  <a-select placeholder="选择类型" v-if="record.editable" :size="size" :ref="'SpecValueRef_' + index" v-model="record.dividend_business" style="width: 180px">
                    <a-select-option v-for="item in specList" :key="item.id" :disabled="isCheak(form.dividend_ratio_list,item.id)">
                      {{ item.name }}
                    </a-select-option>
                  </a-select>
                  <span v-else-if="specList.filter(it=>record.dividend_business==it.id)[0]">{{specList.filter(it=>record.dividend_business==it.id)[0].name}}</span>
                  <span v-else>{{record.dividend_business || '--'}}</span>
                </template>
                <template slot="_1" slot-scope="text, record, index">
                  <template>
                    <a-input-number style="width: 180px" v-if="record.editable" v-model="record.dividend_rate" :min="0" :max="100" />
                    <!-- <a-input v-model="record.dividend_rate"  type="number" style="width: 180px" v-if="record.editable" placeholder="输入百分比" suffix="%" /> -->
                    <span v-else>{{record.dividend_rate}}</span> %
                  </template>
                </template>
              </a-table>
              <h2 class="twoh1 mgt-40">
                <span class="tpointer"></span>
                <span class="ttxt">结算合伙人分红周期</span>
              </h2>
              <span class="mgl-12" style="font-size: 14px;color: @fontColor1;" v-if="$route.query.type == 3">{{form.dividend_settlement_period==1?'按季度结算':'按年结算'}}</span>
              <a-radio-group v-else v-model="form.dividend_settlement_period" :disabled="$route.query.type == 3" class="lable_radiogroup_padding">
                <a-radio-button :value="1">
                  按季度结算
                </a-radio-button>
                <a-radio-button :value="2">
                  按年结算
                </a-radio-button>
              </a-radio-group>
              <!-- 佣金 -->
              <h2 class="twoh1 mgt-40" style="margin-bottom:8px">
                <span class="tpointer"></span>
                <span class="ttxt">佣金<span class="ttxtspan">合伙人获得转介绍客户消费订单佣金计算规则</span></span>
              </h2>
              <a-form-model :label-col="{ span: 2 }" :wrapper-col="{ span: 16 }" labelAlign="left">
                <a-form-model-item label="佣金订单来源">
                  收银流水
                </a-form-model-item>
              </a-form-model>
              <h2 class="twoh3">
                <span class="tpointer"></span>
                <span style="color: #A4B3B1;font-size:14px;margin-right:8px">产品类型 :</span><span class="ttxt">非合作项目
                  <a-tooltip placement="top">
                    <template slot="title">
                      非合作项目: 实物、服务、项目卡、套盒、权益卡(不包含储值卡)
                    </template>
                    <a-icon :style="{ color: '#819190' }" type="info-circle" />
                  </a-tooltip>
                </span>
              </h2>
              <a-table class="mgb-12 mgt-10 detailsTable" style="width:99.9%" :pagination="false" row-key="id" size="middle" :loading="loading1" :columns="config.add.columns_2" :data-source="form.commission_rule_no">
                <template slot="_0" slot-scope="text, record, index">
                  <a-select placeholder="选择类型" v-if="record.editable" :size="size" :ref="'productTypeRef_' + index" v-model="record.payment_method" style="width: 180px">
                    <a-select-option v-for="item in paymentList" :value="item.id" :key="item.id" :disabled="isCheakNo(form.commission_rule_no,item.id)">
                      {{ item.name }}
                    </a-select-option>
                  </a-select>
                  <span v-else-if="paymentList.filter(it=>record.payment_method==it.id)[0]">{{paymentList.filter(it=>record.payment_method==it.id)[0].name}}</span>
                  <span v-else>{{record.payment_method || '--'}}</span>
                </template>
                <template slot="_1" slot-scope="text, record, index">
                  <template>
                    <a-input-number v-model="record.first_purchase_rate" style="width: 180px" v-if="record.editable" :min="0" :max="100" />
                    <!-- <a-input v-model="record.first_purchase_rate" style="width: 180px" v-if="record.editable" placeholder="输入百分比" suffix="%" /> -->
                    <span v-else>{{record.first_purchase_rate}}</span> %
                  </template>
                </template>
                <template slot="_2" slot-scope="text, record, index">
                  <template>
                    <a-input-number v-model="record.repurchase_rate" style="width: 180px" v-if="record.editable" :min="0" :max="100" />
                    <!-- <a-input v-model="record.repurchase_rate" style="width: 180px" v-if="record.editable" placeholder="输入百分比" suffix="%" /> -->
                    <span v-else>{{record.repurchase_rate}}</span> %
                  </template>
                </template>
              </a-table>
              <h2 class="twoh3 mgt-30"><span class="tpointer"></span>
                <span style="color: #A4B3B1;font-size:14px;margin-right:8px">产品类型 :</span><span class="ttxt">合作项目
                  <a-tooltip placement="top">
                    <template slot="title">
                      合作项目:设置为合作项目的实物、服务、项目卡、套盒、权益卡(不包含储值卡)
                    </template>
                    <a-icon :style="{ color: '#819190' }" type="info-circle" />
                  </a-tooltip>
                </span>
              </h2>
              <a-table class="mgb-12 mgt-10 detailsTable" style="width:99.9%" :pagination="false" row-key="id" :loading="loading2" size="middle" :columns="config.add.columns_2" :data-source="form.commission_rule_yes">
                <template slot="_0" slot-scope="text, record, index">
                  <a-select placeholder="选择类型" v-if="record.editable" :size="size" :ref="'productTypeRef_' + index" v-model="record.payment_method" style="width: 180px">
                    <a-select-option v-for="item in paymentListYes" :value="item.id" :key="item.id" :disabled="isCheakNo(form.commission_rule_yes,item.id)">
                      {{ item.name }}
                    </a-select-option>
                  </a-select>
                  <span v-else-if="paymentListYes.filter(it=>record.payment_method==it.id)[0]">{{paymentListYes.filter(it=>record.payment_method==it.id)[0].name}}</span>
                  <span v-else>{{record.payment_method || '--'}} %</span>
                </template>
                <template slot="_1" slot-scope="text, record, index">
                  <template>
                    <a-input-number v-model="record.first_purchase_rate" style="width: 180px" v-if="record.editable" :min="0" :max="100" />
                    <!-- <a-input v-model="record.first_purchase_rate" style="width: 180px" v-if="record.editable" placeholder="输入百分比" suffix="%" /> -->
                    <span v-else>{{record.first_purchase_rate}}</span> %
                  </template>
                </template>
                <template slot="_2" slot-scope="text, record, index">
                  <template>
                    <a-input-number v-model="record.repurchase_rate" style="width: 180px" v-if="record.editable" :min="0" :max="100" />
                    <!-- <a-input v-model="record.repurchase_rate" style="width: 180px" v-if="record.editable" placeholder="输入百分比" suffix="%" /> -->
                    <span v-else>{{record.repurchase_rate}}</span> %
                  </template>
                </template>
              </a-table>
            </div>
          </div>
          <div class="mgt-40" id="product-user" data-anchor="product-user">
            <h2 class="twoh2">
              <span class="tpointer"></span>
              <span class="ttxt">权益配置</span>
            </h2>
            <a-table class="mgb-12 mgt-10 detailsTable" style="width:99.9%" :pagination="false" size="middle" :columns="config.add.columns_3" :data-source="form.benefit">
              <template slot="_1" slot-scope="text, record, index">
                <template v-if="record.image">
                  <img class="wh30Img" :src="record.image" @click="handlePreview(record,'image/*')" alt="">
                </template>
                <span v-else>暂未上传</span>
              </template>
              <template slot="_3" slot-scope="text, record, index">
                <div class="editable-row-operations">
                  <a-button class="smallbtn" @click="lookItemQy(record,index)">查看</a-button>
                </div>
              </template>
            </a-table>
          </div>
          <div class="mgt-40" id="product-file" data-anchor="product-file">
            <h2 class="twoh2">
              <span class="tpointer"></span>
              <span class="ttxt">使用配置</span>
            </h2>
            <a-form-model labelAlign="left">
              <div class="shop-title">
                门店授权<span class="right-title mgl-12">已选 {{$route.query.type == 3?form.shop_list?.length||0:withBrandCheck.length}}家</span>
              </div>
              <template>
                <div class="shop-body">
                  <div class="top flex">
                    <a-input size="small" class="y-input" style="padding-top:9px" v-model="valueInput" placeholder="搜索门店">
                      <i slot="prefix" class="meiye-icon meiye-sousuo"></i>
                    </a-input>
                  </div>
                  <div class="conter flex">
                    <!-- <div class="left" style="padding-top:6px">
                      <div class="item-text" v-if="withListAll?.length" v-for="(it,i) in withBrand" :key="it.id" @click="checkboxgroup(it)">
                        {{ it.province }}
                      </div>
                      <a-list :data-source="withListAll" />
                    </div> -->
                    <div class="right3">
                      <div class="item-text" v-for="it in withListAll" :key="it.id">
                        <a-tooltip placement="top">
                          <template slot="title">
                            {{ it.name }}
                          </template>
                          {{ it.name }}
                        </a-tooltip>
                      </div>
                      <div class="text-center" style="width:100%" v-if="withListAll.length == 0">
                        <a-list :data-source="[]" />
                      </div>
                    </div>
                  </div>
                </div>
              </template>
            </a-form-model>
          </div>
        </a-layout-content>
      </a-layout>
    </div>
    <a-modal title="查看权益" :visible="modal_look.visible" @cancel="modal_look.visible=false" :width="800">
      <div class="row-body">
        <div class="top">
          <div :class="qyStep==1?'yuan-blue':'yuan'">1</div>
          <div class="text-title">基础信息</div>
          <div class="dian">·······</div>
          <div :class="qyStep==2?'yuan-blue':'yuan'">2</div>
          <div class="text-title">权益详情</div>
        </div>
      </div>
      <div class="mgt-20" v-if="qyStep==1">
        <a-form-model :label-col="{ span: 3 }" :wrapper-col="{ span: 16 }" labelAlign="left">
          <a-form-model-item label="权益图">
            <a-avatar shape="square" :size="64" :src="modal_look.form.image" icon="user" />
          </a-form-model-item>
          <a-form-model-item label="权益名称">
            <span>{{modal_look.form.name || '--'}}</span>
          </a-form-model-item>
          <a-form-model-item label="权益次数">
            <span>{{modal_look.form.count_type==1?'不限量':'限量'}}</span>
            <span v-if="modal_look.form.count_type == 2"> {{modal_look.form.count || '--'}} 次 </span>
          </a-form-model-item>
          <a-form-model-item label="权益规格">
            <span>{{modal_look.form.specification || '--'}}</span>
          </a-form-model-item>
          <a-form-model-item label="权益单位">
            <span>{{modal_look.form.unit|| '--'}}</span>
          </a-form-model-item>
        </a-form-model>
      </div>
      <div class="mgt-20" v-if="qyStep==2">
        <a-form-model :label-col="{ span: 3 }" :wrapper-col="{ span: 20 }" labelAlign="left">
          <a-form-model-item label="权益详情">
            <div v-html="modal_look.form.desc">{{modal_look.form.desc}}</div>
          </a-form-model-item>
        </a-form-model>
      </div>
      <template slot="footer">
        <a-button @click="modal_look.visible=false">取消</a-button>
        <a-button v-if="qyStep==1" @click="qyStep=2" type="primary">下一页</a-button>
        <a-button v-if="qyStep==2" @click="qyStep=1">上一页</a-button>
      </template>
    </a-modal>
  </div>
</template>
<script>
import moment from 'moment';
import config from './config_';
import { partnerGenerated, partnerAdd, partnerSave, partnerDetail, InstitutionProvince, partnerPayTypeList } from '@/api/partner'
import UploadImage from './modules/drag'
import ShopEditor from './modules/wangeditor'
import Msg from '@/components/mrj-menu/msg'
import storage from 'store'
import { find, push } from 'store/storages/all';
export default {
  inject: ['reload'],
  components: { UploadImage, ShopEditor },
  data() {
    return {
      config,
      activeKey: 'product-base',
      setId: '',
      loading: false,
      loading1: false,
      loading2: false, isactive: false,
      withBrand: [],
      withBrandList: [],
      withBrandListAll: [],
      withListAll: [],
      valueCheckbox: [],
      is_all_shop: 2,
      withBrandCheck: [],
      valueInput: '',
      yearPickShow: false,
      isAllCheck: true,
      timeData: [],
      application_timeData: [],
      specList: [{ id: 1, name: '合作项目(收现)' }, { id: 2, name: '产品业绩(收现)' }, { id: 3, name: '基础业绩(收现)' }],
      paymentList: [],
      paymentListYes: [],
      form: {//表单数据
        scheme_identifier: '',
        scheme_name: '',
        // start_date: '',
        // end_date: '',
        share_price: 0.00,
        min_shares: 0,
        premium_rate: 0,
        application_start_date: '',
        application_end_date: '',
        identify_end_date: '',
        dividend_ratio_year: '',
        authorized: 1,
        dividend_settlement_period: 2,
        dividend_order_source: 1,
        dividend_ratio_list: [],
        commission_rule: [],
        commission_rule_no: [],
        commission_rule_yes: [],
        benefit: [],
        institution_ids: '',
      },
      qyStep: 1,
      itemQyType: '',
      itemQyIndex: '',
      modal: {
        visible: false,
        url_loading: false,
        form: {
          name: '',
          image: '',
          count: '1',
          specification: '',
          count_type: 1,
          unit: '',
          operate: '',
          desc: '',
        }
      },
      modal_look: {
        visible: false,
        form: ''
      },
      operate: '',
      imgflag: false,
    };
  },
  created() {
    console.log('obthis.$route.queryject :>> ', this.$route.query);
    console.log(this.$route, 'this.$route');
    if (this.$route.query.type != 1) {
      this.setId = this.$route.query.id;
      this.getPartnerDetail(this.$route.query.id);
    } else {
      this.getPartnerGenerated()
    }
    if (this.$route.query.type == 1) {
      this.operate = 'add';
      this.modal.form.operate = 'add';
    }
    if (this.$route.query.type == 2) {
      this.operate = 'save';
      this.modal.form.operate = 'save';
    }
    this.getWithBrand();
    this.getPartnerPayList();
  },
  watch: {
    valueInput: {
      handler(val) {
        this.isAllCheck = true;
        let list = this.form.shop_list;
        if (val) {
          //模糊查询（筛选）
          let arr = list.filter((item) => {
            return item.name.toString().indexOf(val) > -1
          });
          this.withListAll = arr;
        } else {
          this.withListAll = this.form.shop_list
        }
        console.log('withBrandCheck :>> ', this.withBrandCheck);

      },
      deep: true
    },
    valueCheckbox: {
      handler(val) {
        console.log('val :>> ', val);
        console.log('this.withBrandListAll :>> ', this.withBrandListAll);
        let list = [];
        let ids = [];
        this.withBrandListAll.forEach((it, index) => {
          if (val.includes(it.value)) {
            list.push(it)
            ids.push(it.id)
          }
        })
        this.withBrandList = list;
        this.withBrandCheck = ids;
        console.log('withBrandCheck :>> ', this.withBrandCheck);

      },
      deep: true
    }
  },

  methods: {
    moment,
    // 描点
    handleWrapScroll(rec) {
      this.activeKey = this.$utils.onHandleWrapScroll(rec, this.config.tabs)
    },
    isCheak(item, id) {
      console.log('item :>> ', item);
      console.log('id :>> ', id);
      let index = item.findIndex(v => v.dividend_business == id);
      if (index != -1) {
        return true
      }
      console.log('index :>> ', index);
    },
    isCheakNo(item, id) {
      let index = item.findIndex(v => v.payment_method == id);
      if (index != -1) {
        return true
      }
    },
    getPartnerPayList() {
      partnerPayTypeList().then(res => {
        let list = res.data;
        //let一个index来接收findIndex的结果，这里arrText为要查找的数组
        let index = list.findIndex((ele) => {
          return ele.id == 7;
        });
        console.log("index", index);
        //当查找失败返回
        if (index === -1) return console.log('删除失败');
        //如果找到该元素，使用splice方法删除元素
        list.splice(index, 1);
        this.paymentList = list;
        this.paymentListYes = list;

      })
    },

    checkboxgroup(e) {
      console.log('e :>> ', e);
      console.log('valueCheckbox :>> ', this.valueCheckbox);
    },
    // 获取门店
    getWithBrand() {
      InstitutionProvince({ isFilterate: 1 }).then(res => {
        this.withBrand = res.data.map((item, index) => {
          item.institution = item.institution.map(it => {
            return { ...it, value: `${index}level-1`, label: it.name }
          })
          return { ...item, value: `${index}level-1`, label: item.province }
        })
        let list = []
        this.withBrand.forEach((v) => {
          list = list.concat(v.institution)
        })
        console.log('list :>> ', list);
        this.withBrandListAll = list;
        this.withListAll = list;
        console.log('this.withBrand :>> ', this.withBrand);
      })
    },
    // 获取详情信息
    getPartnerDetail(id) {
      if (!id) return;
      partnerDetail({ id: id }).then(res => {
        console.log(res.data, "data");
        let list_no = [];
        let list_yes = [];
        res.data.commission_rules.forEach(element => {
          if (element.product_type == 1) {
            list_yes.push(element);
          }
          if (element.product_type == 2) {
            list_no.push(element);
          }
        });
        this.form = {
          ...res.data,
          share_price: res.data.subscription_setting.share_price,
          min_shares: res.data.subscription_setting.min_shares,
          premium_rate: (Number(res.data.subscription_setting.premium_rate) * 100).toFixed(2),
          // premium_rate: Number(res.data.subscription_setting.premium_rate),
          benefit: res.data.benefits || [],
          authorized: 1,
          dividend_order_source: 1,
          dividend_ratio_list: res.data.dividend_ratio_list.map(val => {
            val.dividend_rate = (Number(val.dividend_rate) * 100).toFixed(2);
            return val;
          }),
          commission_rule_no: list_no.map(val => {
            val.first_purchase_rate = (Number(val.first_purchase_rate) * 100).toFixed(2);
            val.repurchase_rate = (Number(val.repurchase_rate) * 100).toFixed(2);
            return val;
          }),
          commission_rule_yes: list_yes.map(val => {
            val.first_purchase_rate = (Number(val.first_purchase_rate) * 100).toFixed(2);
            val.repurchase_rate = (Number(val.repurchase_rate) * 100).toFixed(2);
            return val;
          }),
        };
        // this.timeData = [res.data.start_date, res.data.end_date];
        this.application_timeData = [res.data.application_start_date, res.data.application_end_date]
        // console.log('this.timeData :>> ', this.timeData);
        console.log('this.form :>> ', this.form);
        this.withListAll = this.form.shop_list
      })
    },
    // 获取方案 id
    getPartnerGenerated() {
      partnerGenerated().then((res) => {
        this.form.scheme_identifier = res.data.scheme_identifier;
      })
    },

    handleOpenChange(status) {
      this.isactive = !this.isactive;
      this.yearPickShow = status;
    },
    onChangeYear(rec) {
      console.log('rec :>> ', rec.format('YYYY'));
      this.form.dividend_ratio_year = rec ? rec.format('YYYY') : '';
      this.yearPickShow = false;
    },

    handlePreview(src, type) {
      this.$utils.previewImgVideo({ type: type, response: { data: { file_url: src.image } } })
    },
    lookItemQy(record, index) {
      this.modal_look.form = record;
      console.log('this.modal_look.form :>> ', this.modal_look.form);
      this.qyStep = 1;
      this.modal_look.visible = true;
    },

    changeRoute() {
      if (this.$route.query.url == 2) {
        let e = this.$route.query.item;
        this.$emit('toGoIndex', e)
      } else {
        console.log('this.$route.fullpath :>> ', this.$route.fullpath);
        this.$router.push({ path: this.$route.fullpath });
        let newobject = storage.get('seconderyMenuObj')
        Msg.$emit('getpathname', newobject)
      }
    }
  },
};
</script>
<style lang="less" scoped>
.main-ctn-2 {
  padding: 30px 0 30px 35px;
  margin: 12px;
  width: 100%;
  height: calc(100vh - 128px);
  background: #fff;
  overflow-y: auto;
}
.twoh1 {
  display: flex;
  margin-bottom: 20px;
  align-items: center;
  .tpointer {
    display: inline-block;
    width: 3px;
    height: 10px;
    background: @primaryColor;
    border-radius: 1px;
    margin-right: 8px;
  }
  .ttxt {
    height: 22px;
    font-size: 14px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    line-height: 22px;
    color: @fontColor1;
  }
  .ttxtspan {
    height: 18px;
    font-size: 13px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #a4b3b1;
    line-height: 18px;
    margin-left: 8px;
  }
}
.twoh2 {
  display: flex;
  margin-bottom: 22px;
  align-items: center;
  .tpointer {
    display: inline-block;
    width: 3px;
    height: 10px;
    background: @primaryColor;
    border-radius: 1px;
    margin-right: 8px;
  }
  .ttxt {
    height: 22px;
    font-size: 16px;
    font-weight: 600;
    color: @fontColor1;
    line-height: 25px;
  }
}
.twoh3 {
  display: flex;
  margin-bottom: 12px;
  align-items: center;
  .tpointer {
    display: inline-block;
    width: 6px;
    height: 6px;
    background: #aed6d8;
    border-radius: 1px;
    margin-right: 8px;
    margin-bottom: 2.5px;
  }
  .ttxt {
    font-size: 14px;
    font-weight: 500;
    color: @fontColor1;
  }
}
.textColor1 {
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #131010;
  margin-bottom: 8px;
  .xing {
    color: #f35e67;
    margin-left: 1px;
    font-weight: 500;
  }
}
.textSmall {
  font-size: 13px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: @primaryColor;
  line-height: 26px;
  height: 26px;
  background: @gray-border-background;
  border-radius: 4px;
  opacity: 0.65;
  padding-left: 8px;
  margin-top: 13px;
}
.a-center {
  display: flex;
  align-items: center;
  margin-left: 16px;
  .mgl-8 {
    font-size: 14px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    margin-left: 8px;
  }
}

.shop-title {
  margin: 24px 0 20px 12px;
  height: 22px;
  font-size: 14px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: @fontColor1;
  line-height: 22px;
}
.left-title {
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: rgba(45, 56, 53, 0.8);
  margin-bottom: 30px;
  margin-left: 11px;
}
.right-title {
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  margin-bottom: 30px;
  font-weight: 400;
  color: @fontColor1;
}
.y-center {
  align-items: center;
  width: 200px;
  justify-content: flex-end;
}
.shop-body {
  background: #fafcfc;
  border-radius: 6px;
  border: 1px solid @borderColorBase;
  margin-left: 12px;
  width: 96%;
  /deep/.ant-input {
    width: 200px;
    border: 1px solid #fafcfc !important;
    background: none !important;
    box-shadow: none !important;
  }
  /deep/.ant-input:hover,
  /deep/.ant-input:focus,
  /deep/.ant-input:focus {
    border: 1px solid @primaryColor !important;
    box-shadow: inset 0px 3px 0px 0px var(--inputinsertHColor, #eaf1f0) !important;
  }
  .top {
    height: 51px;
    line-height: 51px;
    padding-left: 12px;
    padding-right: 51px;
    border-bottom: 1px solid @borderColorBase;
    .left {
      width: 214px;
    }
    .right {
      width: calc(100% - 214px);
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: rgba(45, 56, 53, 0.8);
      display: flex;
      align-items: baseline;
      justify-content: space-between;
    }
  }

  .conter {
    padding-right: 51px;
    .left {
      width: 240px;
      height: 525px;
      padding: 0 12px 12px 6px;
      overflow-y: auto;
      overflow-x: hidden;
      border-right: 1px solid @borderColorBase;
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      .item-text {
        padding: 6px;
        margin: 3px;
        display: block;
        cursor: pointer;
      }
      .item-text:hover {
        background: @baseColor58;
        color: @primaryColor;
        border-radius: 3px;
      }
      .item-text-corlor {
        background: @baseColor58;
        color: @primaryColor;
        border-radius: 3px;
      }
    }
    .right3 {
      padding: 6px 12px;
      overflow-y: auto;
      display: flex;
      flex: 1;
      height: 525px;
      flex-wrap: wrap;
      align-content: flex-start;
      .item-text {
        padding: 6px;
        margin: 3px;
        width: 16%;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
  }
}
.row-body {
  .top {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    .text-title {
      margin-left: 10px;
      font-size: 14px;
      font-weight: 400;
      color: @btnColorfont1;
    }
    .yuan-blue {
      width: 38px;
      height: 38px;
      text-align: center;
      line-height: 38px;
      color: #ffffff;
      border-radius: 100%;
      background: @inputborderHColor;
      border: 1px solid #0aada1;
    }
    .dian {
      color: #e5e8e5;
      margin: 0 10px;
    }
    .yuan {
      width: 38px;
      height: 38px;
      text-align: center;
      line-height: 38px;
      color: @fontColor7;
      border-radius: 100%;
      background: #fdfdff;
      border: 1px solid #a0c7c4;
    }
  }
}

/deep/ .detailsTable .ant-table .ant-table-thead > tr > th {
  padding: 1px 8px !important;
}
/deep/ .detailsTable .ant-table .ant-table-thead > tr > th:nth-child(1) {
  padding-left: 24px !important;
}
/deep/ .detailsTable .ant-table-row > td:nth-child(1) {
  padding-left: 24px !important;
}

.font-22 {
  font-size: 22px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: @fontColor1;
}

.wh30Img {
  width: 42px;
  height: 32px;
  box-shadow: 0px 2px 5px 0px rgba(204, 228, 226, 0.35);
  border-radius: 2px;
  border: 1px solid #0bc7b9;
}
.xing {
  font-size: 14px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #f35e67;
}
/deep/.ant-checkbox-wrapper + .ant-checkbox-wrapper {
  margin-left: 0 !important;
}
.y-tag {
  margin-right: 12px;
  padding: 2px 7px;
  font-size: 12px;
  line-height: 20px;
  white-space: nowrap;
  background: #fafafa;
  border: 1px solid #d9d9d9;
  border-radius: 4px;
}

.plus-icon {
  width: 24px;
  height: 24px;
  padding-top: 3px;
  background: #ffffff;
  box-shadow: 0px 2px 3px 0px rgba(173, 207, 203, 0.35),
    inset 0px -3px 0px 0px #dbeae8;
  border-radius: 5px;
  cursor: pointer;
  border: 1px solid #bacbc7;
}
.plus-icon-two {
  font-size: 13.8px !important;
  text-align: center;
  padding-top: 0px !important;
  display: inline-block;
  color: @fontColor1;
  font-weight: 400;
}
.mgt-40 {
  margin-top: 40px;
}
.mgt-20 {
  /deep/.avatar-uploader .ant-upload {
    width: 110px;
    height: 82px;
  }
}
</style>