/*
 * @Version: 2.0
 * @Autor: YangKX
 * @Date: 2023-05-12 10:53:56
 * @LastEditors: YangKX
 * @LastEditTime: 2023-05-19 00:26:59
 */

export default {
  customer: {
    columns: [
      {
        title: "合伙人方案",
        dataIndex: "scheme_name",
        key: "_0",
        ellipsis: true,
        scopedSlots: { customRender: "_0" },
      },
      {
        title: "方案效期",
        dataIndex: "created_at",
        key: "_11",
        ellipsis: true,
        scopedSlots: { customRender: "_11" },
      },
      {
        title: "收款金额",
        dataIndex: "subscription_fee",
        key: "_1",
        ellipsis: true,
        scopedSlots: { customRender: "_1" },
      },
      {
        title: "合伙人",
        dataIndex: "name",
        key: "_2",
        customRender: (str) => str || "--",
        ellipsis: true,
        scopedSlots: { customRender: "_2" },
      },
      {
        title: "支付方式",
        dataIndex: "pay_type_text",
        key: "_3",
        ellipsis: true,
        scopedSlots: { customRender: "_3" },
      },
      {
        slots: { title: "_time" },
        // title: "认购时间",
        dataIndex: "subscription_time",
        key: "_4",
        ellipsis: true,
        scopedSlots: { customRender: "_4" },
      },
    ],
  },
};
