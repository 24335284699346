/*
 * @Version: 2.0
 * @Autor: YangKX
 * @Date: 2023-05-05 17:25:32
 * @LastEditors: YangKX
 * @LastEditTime: 2023-05-16 13:20:16
 */
export default {
    customer: {
      columns: [
        {
          title: "合伙人方案",
          dataIndex: "scheme_name",
          key: "_0",
          width:'20%',
          ellipsis: true,
          scopedSlots: { customRender: "_0" },
        },
        {
          title: "佣金",
          dataIndex: "commission_amount",
          width:'20%',
          key: "_1",
          ellipsis: true,
          scopedSlots: { customRender: "_1" },
        },
        {
          title: "合伙人",
          dataIndex: "name",
          width:'20%',
          key: "_2",
          ellipsis: true,
          scopedSlots: { customRender: "_2" },
        },
        {
          title: "佣金订单号",
          dataIndex: "sn",
          width:'20%',
          key: "_4",
          ellipsis: true,
          scopedSlots: { customRender: "_4" },
        },
        {
          // title: "结算时间",
        slots: { title: "_time" },
          dataIndex: "settlement_time",
          width:'20%',
          key: "_5",
          ellipsis: true,
          scopedSlots: { customRender: "_5" },
        }
      ],
    },
  };