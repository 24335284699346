export default {
  tabs: [
    {
      id: "product-base",
      label: "基本配置",
    },
    {
      id: "product-detail",
      label: "收益配置",
    },
    {
      id: "product-user",
      label: "权益配置",
    },
    {
      id: "product-file",
      label: "使用配置",
    },
  ],
  customer: {
    columns: [
      {
        title: "合伙人方案ID",
        dataIndex: "scheme_identifier",
        key: "_0",
        ellipsis: true,
        width: 130,
        scopedSlots: { customRender: "_0" },
      },
      {
        title: "方案名称",
        dataIndex: "scheme_name",
        key: "_1",
        ellipsis: true,
        width: 230,
        scopedSlots: { customRender: "_1" },
      },
      {
        title: "使用门店",
        dataIndex: "shop_count",
        key: "_2",
        width: 110,
        customRender: (str) => str + "家" || "--",
        ellipsis: true,
        scopedSlots: { customRender: "_2" },
      },
      {
        title: "合伙人",
        dataIndex: "partner_count",
        width: 110,
        key: "_3",
        ellipsis: true,
        customRender: (str) => str + "人" || "--",
        scopedSlots: { customRender: "_3" },
      },
      {
        title: "转介绍人",
        dataIndex: "inviter_count",
        width: 110,
        key: "_4",
        ellipsis: true,
        customRender: (str) => str + "人" || "--",
        scopedSlots: { customRender: "_4" },
      },
      {
        title: "状态",
        dataIndex: "status_text",
        key: "_5",
        width: 120,
        ellipsis: true,
        scopedSlots: { customRender: "_5" },
      },
      {
        title: "创建人",
        dataIndex: "name",
        width: 160,
        key: "_6",
        ellipsis: true,
        scopedSlots: { customRender: "_6" },
      },
      {
        title: "创建时间",
        dataIndex: "created_at",
        key: "_7",
        width: 180,
        ellipsis: true,
        scopedSlots: { customRender: "_7" },
      },
      {
        title: "操作",
        dataIndex: "action",
        key: "_8",
        width: 200,
        fixed: "right",
        scopedSlots: { customRender: "_8" },
      },
    ],
  },
  add: {
    columns_1: [
      {
        title: "类型",
        dataIndex: "dividend_business",
        key: "_0",
        width: "20%",
        scopedSlots: { customRender: "_0" },
      },
      {
        title: "分红比例",
        dataIndex: "dividend_rate",
        key: "_1",
        width: "20%",
        scopedSlots: { customRender: "_1" },
      },
      {
        dataIndex: "end",
        width: "30%",
      },
      {
        dataIndex: "end2",
        width: "30%",
      },
    ],
    columns_1_c: [
      {
        title: "类型",
        dataIndex: "dividend_business",
        key: "_0",
        width: "25%",
        scopedSlots: { customRender: "_0" },
      },
      {
        title: "分红比例",
        dataIndex: "dividend_rate",
        key: "_1",
        width: "25%",
        scopedSlots: { customRender: "_1" },
      },
      {
        title: "操作",
        align: "center",
        dataIndex: "actions",
        width: "20%",
        key: "_3",
        scopedSlots: { customRender: "_3" },
      },
      {
        dataIndex: "end",
        width: "30%",
      },
    ],
    columns_2: [
      {
        title: "收款方式",
        dataIndex: "payment_method",
        key: "_0",
        width: "25%",
        scopedSlots: { customRender: "_0" },
      },
      {
        title: "首次付款分佣比例",
        dataIndex: "first_purchase_rate",
        key: "_1",
        width: "25%",
        scopedSlots: { customRender: "_1" },
      },
      {
        title: "再次付款分佣比例",
        dataIndex: "repurchase_rate",
        key: "_2",
        width: "25%",
        scopedSlots: { customRender: "_2" },
      },
    ],
    columns_2_c: [
      {
        title: "收款方式",
        dataIndex: "payment_method",
        key: "_0",
        width: "25%",
        scopedSlots: { customRender: "_0" },
      },
      {
        title: "首次付款分佣比例",
        dataIndex: "first_purchase_rate",
        key: "_1",
        width: "25%",
        scopedSlots: { customRender: "_1" },
      },
      {
        title: "再次付款分佣比例",
        dataIndex: "repurchase_rate",
        key: "_2",
        width: "25%",
        scopedSlots: { customRender: "_2" },
      },
      {
        title: "操作",
        dataIndex: "actions",
        align: "center",
        key: "_3",
        scopedSlots: { customRender: "_3" },
      },
      {
        dataIndex: "end",
        width: "15%",
      },
    ],
    columns_3: [
      {
        title: "权益名称",
        dataIndex: "name",
        width: "20%",
        key: "_0",
        scopedSlots: { customRender: "_0" },
      },
      {
        title: "权益图",
        dataIndex: "image",
        width: "20%",
        key: "_1",
        scopedSlots: { customRender: "_1" },
      },
      {
        title: "权益次数",
        dataIndex: "count",
        width: "20%",
        key: "_2",
        scopedSlots: { customRender: "_2" },
      },
      {
        title: "操作",
        width: "20%",
        key: "_3",
        scopedSlots: { customRender: "_3" },
      },
      {
        dataIndex: "end",
        width: "20%",
      },
    ],
  },
};
