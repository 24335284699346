<template>
  <div>
    <div v-if="showType == 1" class="page-list personnelfiles flex-1">
      <a-row class="filter-wrap">
        <a-col :lg="8" :md="24" :sm="24" :xs="24" style="margin-left:0px;" class="mgb-20 ant-input_2">
          <a-input v-model="query.search_key" style="width: 330px;" placeholder="输入门店名称/简称/机构代码查询" @pressEnter="search" allowClear @change="queryChange">
            <i slot="prefix" class="meiye-icon meiye-sousuo"></i>
          </a-input>
        </a-col>
      </a-row>
      <a-table :customRow="rowClick"  class="table-manage flex-1" :scroll="{ x: '100%',y:$utils.getfullHeight(290) }" @change="tableChanged" :pagination="table.pagination" row-key="member_id" :loading="table.loading" :columns="config.customer.columns" :data-source="table.tableData">
        <template slot="_4" slot-scope="text, record">
          <span class="cursor-pointer c-p-hover" v-if="record.scheme_name" style="color: #1BA196;" @click.stop="toSchemeDetail(record, 1)">{{ record.scheme_name }} </span>
          <span v-else>--</span>
        </template>
        <template slot="_8" slot-scope="action, record">
          <a-button size="small" class="mgr-12 smallbtn" @click.stop="toDetail(record, 1)">查看</a-button>
        </template>
      </a-table>
    </div>
    <template v-if="showType == 2">
      <Detail @onCancel="onCancel"></Detail>
    </template>
    <template v-if="showType == 3">
      <AddDetail @onCancel="onCancel"></AddDetail>
    </template>
  </div>
</template>
<script>
import storage from 'store'
import config from './config'
import AddDetail from './add.vue'
import { partnerUserShopList } from '@/api/partner'
import { text } from 'body-parser'
import Detail from './components/detail.vue'

export default {
  components: { Detail, AddDetail },

  data() {
    return {
      config,
      baseUrl: process.env.VUE_APP_API_BASE_URL,
      headers: {
        token: storage.get('token'),
        "Content-Type": 'multipart/form-data'
      },
      showType: 1,
      query: {
        per_page: 10,
        page: 1,
        search_key: '',
        sort_field: '',
        sort: ''
      },
      table: {
        tableData: [],
        loading: false,
        pagination: {
          current: 1,
          total: 0,
          pageSize: 10, //每页中显示10条数据
          showSizeChanger: true,
          showQuickJumper: true,
        },
      }
    }
  },
  created() {
    this.getList()
  },
  methods: {
    rowClick(record, index) {
      return {
        on: {
          click: () => {
            this.toDetail(record, 1)
          }
        }
      }
    },
    queryChange(e) {
      console.log(e)
      if (e.type == 'click') {
        this.getList()
      }
    },
    search(e) {
      this.query.page = 1
      this.getList()
    },
    onCancel() { this.showType = 1; this.getList() },
    // 结束 
    handleBatch(key, record, title = "删除") {
      //   let self = this
      //   let deleteRules = ''
      //   const urlArr = [
      //     { name: deleteRules, key: 'delete', okType: 'primary', msg: '确定要删除吗？删除后将无法恢复，请谨慎操作！' },
      //   ]
      //   const activeObj = urlArr.filter(it => it.key == key)[0]
      //   self.$confirm({
      //     icon: 'exclamation-circle',
      //     closable: true,
      //     title,
      //     content: activeObj.msg,
      //     okText: '确定',
      //     closable: true,
      //     okType: activeObj.okType,
      //     cancelText: '取消',
      //     onOk() {
      //       const data = {
      //         employee_ids: selectedRowKeys,
      //       };
      //       (activeObj.name)(data).then(res => {
      //         self.getList()
      //       }).finally(r => {
      //       })
      //     },
      //     onCancel() {
      //     },
      //   });
    },
    getList() {
      let self = this
      self.table.loading = true
      const params = {
        page: self.query.page,
        per_page: self.query.per_page,
        search_key: self.query.search_key
      }

      return partnerUserShopList(this.query).then(res => {
        console.log(res.data, "data");
        const { data = [], total = 0 } = res.data
        self.table.tableData = data
        self.table.pagination = Object.assign({}, { pageSize: this.query.per_page, current: this.query.page }, {
          total: total
        })
      }).finally(r => {
        self.table.loading = false
      })
    },
    tableChanged(pagination, filters, sorter, { currentDataSource }) {
      const { current, pageSize } = pagination
      console.log('sorter=>>>', sorter)
      const { field, order } = sorter
      switch (field) {
        case 'subscription_fee':
          this.query.sort_field = 1
          break;
        case 'settlement_commission_amount':
          this.query.sort_field = 2
          break;
        case 'settlement_dividend_amount':
          this.query.sort_field = 3
          break;
      }
      this.query = Object.assign(this.query, { per_page: Number(pageSize), page: Number(current), sort: order ? order == 'ascend' ? 'desc' : 'asc' : '' })
      this.getList()
    },
    // 复制
    copy(e, i) { },
    //查看详情
    toDetail(e, i) {
      this.showType = 2
      this.$router.push({ path: 'partnerMenuUser', query: { id: e.id, partner_plan_id: e.partner_plan_id, shop_name: e.shop_name } })
    },
    // 查看方案详情
    toSchemeDetail(e) {
      this.showType = 3
      this.$router.push({ path: 'partnerMenuUser', query: { type: 3,url:1, id: e.partner_plan_id } })
    },
    // 编辑
    editUser(e, i) { },
    // 添加
    addUser() {
      this.$router.push({ path: 'add' })
    },
  },

}
</script>
<style lang="less" scoped>
.page-list {
  background: #fff;
  min-height: calc(100vh - 88px);
  // margin: 18px;
  border-radius: 2px;
}
</style>