<template>
  <div>
    <div>
      <!-- 工具栏 -->
      <Toolbar :editor="editor" :defaultConfig="toolbarConfig" />
      <!-- 编辑器 -->
      <Editor :defaultConfig="editorConfig" v-model="value" @onChange="onChange" @onCreated="onCreated" ref="seditor" />
    </div>
  </div>
</template>
<script>

import Vue from 'vue'
import { Editor, Toolbar } from '@wangeditor/editor-for-vue'
import storage from 'store'
import axios from 'axios'

export default Vue.extend({
  data() {
    return {
      editor: null,
      html: this.value,
      toolbarConfig: {
        // toolbarKeys: [],
        excludeKeys: [
          // "headerSelect",
          'italic',
          "blockquote",
          "bulletedList",
          "group-justify",
          "group-indent",
          "insertLink",
          "todo",
          "fontSize",
          "group-video",
          // "group-more-style",
          "codeBlock",
          "color",
          "fontFamily",
          "lineHeight",
          "divider",
          "numberedList",
          "fullScreen",
          "insertTable",
          "blockquote",
          "todo",
          "emotion"

        ],
      },
      editorConfig: {
        placeholder: "请输入...",
        autoFocus: false,

        // 所有的菜单配置，都要在 MENU_CONF 属性下
        MENU_CONF: {},
      },
      uploadImgForm: {

      },
      content: "",
    }
  },
  name: "MyEditor",
  components: { Editor, Toolbar },
  props: ['value'],   // 组件引用时添加v-model指令，所以value属性就存在，他的值为v-model等于号后面的变量，通过props将他传递过来
  methods: {
    onCreated(editor) {
      // console.log(editor.getAllMenuKeys());
      // [
      //   "bold", "underline", "italic", "through", "code", "sub",
      //   "sup", "clearStyle", "color", "bgColor", "fontSize", "fontFamily",
      //   "indent", "delIndent", "justifyLeft", "justifyRight", "justifyCenter",
      //   "justifyJustify", "lineHeight", "insertImage", "deleteImage", "editImage",
      //   "viewImageLink", "imageWidth30", "imageWidth50", "imageWidth100", "divider",
      //   "emotion", "insertLink", "editLink", "unLink", "viewLink", "codeBlock",
      //   "blockquote", "headerSelect", "header1", "header2", "header3", "header4",
      //   "header5", "todo", "redo", "undo", "fullScreen", "bulletedList", "numberedList",
      //   "insertTable", "deleteTable", "insertTableRow", "deleteTableRow", "insertTableCol",
      //   "deleteTableCol", "tableHeader", "tableFullWidth", "insertVideo", "uploadVideo",
      //   "uploadImage", "codeSelectLang"
      // ]
      this.editor = Object.seal(editor) // 一定要用 Object.seal() ，否则会报错

    },
    onChange(editor) {

      console.log("onChange", editor.getHtml()); // onChange 时获取编辑器最新内容
      this.value = editor.getHtml()
      this.$emit('transfer', editor.getHtml())
    },
    getEditorText() {
      const editor = this.editor;
      if (editor == null) return;

      console.log(editor.getText()); // 执行 editor API
    },
    printEditorHtml() {
      const editor = this.editor;
      if (editor == null) return;

      console.log(editor.getHtml()); // 执行 editor API
    },
    change(e) {
      // v-model 指令是会给此节点添加一个input事件，我们在给富文本编辑器添加一个input事件，这么做在于，当输入内容后会获取到最新的内容，通过$emit 触发 父组件v-model所添加的input事件并将最新的内容传递过去，传递过去的值默认就会赋给v-model所绑定的属性。
      this.$emit('input', e.target.innerHTML)
    },
    filesToBase64(files) {
      let _this = this
      files.map(item => {
        var reader = new FileReader()
        reader.onload = function (e) {
          _this.uploadImage(e.target.result, item) // 执行上传图片的接口请求
        }
        reader.readAsDataURL(item)
      })
    },
    // 将base64 转换为blob型
    base64toBlob({ base64, success, err }) {
      var arr = base64.split(',')
      var mime = arr[0].match(/:(.*?);/)[1]
      var bstr = atob(arr[1])
      var n = bstr.length
      var u8arr = new Uint8Array(n)
      while (n--) {
        u8arr[n] = bstr.charCodeAt(n)
      }
      let blob = new Blob([u8arr], { type: mime })
      success(blob)
    },

    uploadImage(base64, file) {
      let _this = this
      _this.base64toBlob({
        base64,
        success(blob) {
          let formdata = new FormData()
          // 上传完成，表单储存数组
          _this.uploadImgForm.file = file
          // 转formData格式发送数据
          Object.keys(_this.uploadImgForm).forEach((key) => {
            formdata.append(key, _this.uploadImgForm[key])
          })
          axios.post($Config.$actionUrl, formdata, {
            headers: {
              'Content-Type': 'multipart/form-data'
            }
          }).then(res => {
            var url = res.data.data.uploadPath

          })
        }
      }).catch((err) => {
        console.log(err);
      })
    },
  },
  mounted() {
  },
  created() {
    var self = this;
    console.log(this.editor, "editor");
    console.log(this.value);
    self.editorConfig.placeholder = "请输入权益详情...";
    self.editorConfig.MENU_CONF["uploadImage"] = {
      onBeforeUpload(files) {
        return files; // 返回哪些文件可以上传
        // return false 会阻止上传
      },
      onProgress(progress) {
        console.log("onProgress", progress);
      },
      onSuccess(file, res) {
        console.log("onSuccess", file, res);
      },
      onFailed(file, res) {
        alert(res.message);
        console.log("onFailed", file, res);
      },
      onError(file, err, res) {
        // alert(err.message);
        console.error("onError", file, err, res);
      },

      // 用户自定义上传图片
      async customUpload(file, insertFn) {
        // let self = this
        console.log(file, 'file');
        var fd = new FormData();

        fd.append("file", file); // file 即选中的文件
        console.log(fd);
        var config = {
          method: "post",
          url: process.env.VUE_APP_API_BASE_URL + '/upload',
          headers: {
            "Content-Type": "multipart/form-data",
            authorization: storage.get('token'),
          },
          data: fd
        };

        axios(config)
          .then(function (res) {
            console.log(self);
            if (res.data.error) {
              self.$message.error(res.data.error.message)
              // alert(res.data.error.message);
              return false
            }
            // debugger
            let url = res.data.data.url //拼接成可浏览的图片地址
            insertFn(url, "", url); //插入图片
          })
          .catch(function (error) {
            console.log(error);
          });
      }
    }


  },
  beforeDestroy() {
    const editor = this.editor;
    if (editor == null) return;
    editor.destroy(); // 组件销毁时，及时销毁 editor ，重要！！！
  },
  watch: {
    value() {
      return this.html
    }
  }
})
</script>
<style src="@wangeditor/editor/dist/css/style.css"></style>
