<template>
  <div class="myFileList">
    <a-spin :spinning="confirmLoading">
      <div class="ant-upload-list ant-upload-list-picture">
        <draggable class="syllable_ul" element="ul" :list="fileList" :options="{group:'title', animation:150}" :no-transition-on-drag="true" @change="change">
          <transition-group type="transition" :name="!drag? 'syll_li' : null" :css="true">
            <div class="ant-upload-list-item ant-upload-list-item-done" v-for="(item, index) in fileList" :key="index">
              <div class="ant-upload-list-item-info">
                <span>
                  <a :href="item.url" target="_blank" rel="noopener noreferrer" class="ant-upload-list-item-thumbnail">
                    <img :src="item.url" alt=" ">
                  </a>
                </span>
              </div>
              <i aria-label="图标: close" tabindex="-1" class="anticon anticon-close" @click="removeCoupon(item.uid)"><svg viewBox="64 64 896 896" data-icon="close" width="1em" height="1em" fill="currentColor">
                  <path d="M563.8 512l262.5-312.9c4.4-5.2.7-13.1-6.1-13.1h-79.8c-4.7 0-9.2 2.1-12.3 5.7L511.6 449.8 295.1 191.7c-3-3.6-7.5-5.7-12.3-5.7H203c-6.8 0-10.5 7.9-6.1 13.1L459.4 512 196.9 824.9A7.95 7.95 0 0 0 203 838h79.8c4.7 0 9.2-2.1 12.3-5.7l216.5-258.1 216.5 258.1c3 3.6 7.5 5.7 12.3 5.7h79.8c6.8 0 10.5-7.9 6.1-13.1L563.8 512z"></path>
                </svg></i>
            </div>
          </transition-group>
        </draggable>
      </div>
      <a-upload v-if="fileList.length<5" listType="picture" class="upload-list-inline " :fileList="[]" :customRequest="uploadImage" :beforeUpload="beforeUpload" :multiple="true">
        <div class="avatar-uploader"><a-icon type='plus' /></div>

      </a-upload>
    </a-spin>
    <div class="posi_tips">最多上传5张图片，建议上传750x750px 图片</div>
  </div>
</template>
<script>
import axios from 'axios'
import draggable from 'vuedraggable'
import storage from 'store'
export default {
  name: 'UploadImage',
  props: {
    multiple: {
      type: Number,
      default: 1
    },
    img: {
      type: Array,
      default: function () {
        return []
      }
    }
  },
  data() {
    return {
      fileList: [],
      imageId: [],
      files: [],
      a: [],
      confirmLoading: false,
      drag: false,
      pulsshow: true,
      baseUrl: process.env.VUE_APP_API_BASE_URL2,
      headers: {
        "Authorization": localStorage.getItem("Access-Token"),
        "Content-Type": 'multipart/form-data'
      },
    }
  },
  components: {
    draggable
  },
  methods: {
    // 需要把排序过后的数据 传给父组件
    change(evt) {
      this.$emit('draggable', this.fileList)
    },
    beforeUpload(file) {

      if (this.fileList.length > 5) {
        this.$notification['error']({
          message: '最多上传五张'
        })
      }
      const isJPG = file.type === 'image/jpeg' || file.type === 'image/jpg' || file.type === 'image/png' || file.type === 'image/bmp'
      if (!isJPG) {
        this.$notification['error']({
          message: '请上传图片文件'
        })
      }
      // const isLt2M = file.size / 1024 < 200 && file.size / 1024 > 10;
      // if (!isLt2M) {
      //   this.$message.error('文件大小应在10KB~20KB之间');
      // }
      // return isJPG && isLt2M
      return isJPG
    },
    removeCoupon: function (file) {
      this.fileList.forEach((val, key) => {
        if (file === val.uid) {
          this.fileList.splice(key, 1)
          this.$emit('delimageId', key)
        }
      })
    },
    // 图片预览
    uploadImg: function (e) {
      let _this = this
      if (!e || !window.FileReader) return
      let reader = new FileReader()
      reader.readAsDataURL(e.file)
      reader.onloadend = function () {
        _this.uploadImage()
      }
    },
    uploadImage: function (e) {
      this.confirmLoading = true
      let formdata = new FormData()
      formdata.append('file', e.file)
      axios({
        url: this.baseUrl + 'api/v2/tools/upload',
        method: 'post',
        data: formdata
      }).then((res) => {
        this.confirmLoading = false
        if (this.multiple === 1) {
          this.fileList = [{
            uid: '-1', // 文件唯一标识，建议设置为负数，防止和内部产生的 id 冲突
            name: e.file.name, // 文件名
            status: 'done', // 状态有：uploading done error removed
            // url: this.GLOBAL.imgUrl + res.result.fileId
            url: res.data.data.url
          }]
        } else {
          if (this.fileList.length < this.multiple) {
            this.fileList.push({
              uid: '-' + (this.fileList.length + 1), // 文件唯一标识，建议设置为负数，防止和内部产生的 id 冲突
              name: e.file.name, // 文件名
              status: 'done', // 状态有：uploading done error removed
              // url: this.GLOBAL.imgUrl + res.result.fileId
              url: res.data.data.url
            })
          }
        }
        //  debugger
        console.log(this.fileList, this.img.length);

        this.$emit('getImageId', res.data.data.url)

        if (this.fileList.length < 5) {
          this.pulsshow = true

        } else {
          this.pulsshow = false
        }
      })
    }
  },
  mounted() {
    if (this.img.length > 0) {
      this.img.forEach((val, key) => {
        this.fileList.push({
          uid: '-' + key, // 文件唯一标识，建议设置为负数，防止和内部产生的 id 冲突
          // name: '' + val.name, // 文件名
          status: 'done', // 状态有：uploading done error removed
          url: '' + val
        })
      })
    }
  }
}
</script>
<style lang="less" scoped>
ul {
  padding: 0;
}
.syllable_ul {
  span {
    max-width: 582px;
    display: flex;
    flex-wrap: wrap;
  }
}
.ant-upload-list-item {
  width: 66px;
  margin-right: 12px;
}
.ant-upload-list-picture .anticon-close,
.ant-upload-list-picture-card .anticon-close {
  position: absolute;
  top: 2px;
  right: 0px;
  line-height: 1;
  opacity: 1;
}
/deep/ .ant-upload-select-picture {
  width: 66px;
  height: 66px;
  text-align: center;
  line-height: 66px;
  border-radius: 4px;  
  border: 1px dashed #d9d9d9;
  // margin-top: 8px;
  margin-right: 12px;
  &:hover {
    border-color: @primaryColor;
    i {
      color: @primaryColor;
    }
  }
  i {
    color: #999;
    margin-top: 25px;
    &:hover {
      color: @primaryColor;
    }
  }
}
/deep/ .ant-spin-container {
  display: flex;
  justify-content: flex-end;
}
.posi_tips {
  margin-top: 12px;
}
</style>
