export default {
    customer: {
      columns: [
        {
          title: "合伙人方案",
          dataIndex: "scheme_name",
          key: "_0",
          ellipsis: true,
          scopedSlots: { customRender: "_0" },
        },
        {
          title: "实际分红",
          dataIndex: "actual_dividend_amount",
          key: "_1",
          ellipsis: true,
          scopedSlots: { customRender: "_1" },
        },
        {
          title: "合伙人",
          dataIndex: "name",
          key: "_2",
          ellipsis: true,
          scopedSlots: { customRender: "_2" },
        },
        {
          title: "手机号",
          dataIndex: "phone",
          key: "_4",
          ellipsis: true,
          scopedSlots: { customRender: "_4" },
        },
        {
          title: "统计月份",
          dataIndex: "dividend_month",
          key: "_5",
          ellipsis: true,
          scopedSlots: { customRender: "_5" },
        },
        {
          title: "合伙人分红订单号",
          dataIndex: "sn",
          key: "_6",
          ellipsis: true,
          scopedSlots: { customRender: "_6" },
        },
        {
          // title: "结算时间",
        slots: { title: "_time" },
          dataIndex: "settlement_time",
          key: "_7",
          ellipsis: true,
          scopedSlots: { customRender: "_7" },
        }
      ],
    },
  };