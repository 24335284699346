/*
 * @Version: 2.0
 * @Autor: YangKX
 * @Date: 2023-05-05 17:25:32
 * @LastEditors: YangKX
 * @LastEditTime: 2023-05-16 13:20:07
 */

export default {
  customer: {
    columns: [
      {
        title: "合伙人方案",
        dataIndex: "scheme_name",
        key: "_0",
        width: "20%",
        ellipsis: true,
        scopedSlots: { customRender: "_0" },
      },
      {
        title: "支付金额/元",
        dataIndex: "amount",
        width: "20%",
        key: "_1",
        ellipsis: true,
        scopedSlots: { customRender: "_1" },
      },
      {
        slots: { title: "_tityle" },
        dataIndex: "actual_amount",
        key: "_2",
        width: "20%",
        ellipsis: true,
        scopedSlots: { customRender: "_2" },
      },
      {
        title: "合伙人",
        dataIndex: "name",
        key: "_4",
        width: "20%",
        ellipsis: true,
        scopedSlots: { customRender: "_4" },
      },
      {
        slots: { title: "_time" },
        // title: "支付时间",
        dataIndex: "transaction_time",
        width: "20%",
        key: "_5",
        ellipsis: true,
        scopedSlots: { customRender: "_5" },
      },
    ],
  },
};
