/*
 * @Version: 2.0
 * @Autor: YangKX
 * @Date: 2023-05-05 17:25:22
 * @LastEditors: YangKX
 * @LastEditTime: 2023-05-18 10:58:17
 */
export default {
  customer: {
    columns: [
      {
        title: "门店",
        dataIndex: "shop_abbreviation",
        key: "_0",
        ellipsis: true,
        scopedSlots: { customRender: "_0" },
      },
      {
        title: "累计认购金",
        dataIndex: "subscription_fee",
        key: "_1",
        ellipsis: true,
        sorter: true,
        scopedSlots: { customRender: "_1" },
      },
      {
        title: "结算佣金",
        dataIndex: "settlement_commission_amount",
        key: "_2",
        ellipsis: true,
        sorter: true,
        scopedSlots: { customRender: "_2" },
      },
      {
        title: "实际结算分红",
        dataIndex: "settlement_dividend_amount",
        key: "_3",
        ellipsis: true,
        sorter: true,
        scopedSlots: { customRender: "_3" },
      },
      {
        title: "进行中方案",
        dataIndex: "scheme_name",
        key: "_4",
        ellipsis: true,
        scopedSlots: { customRender: "_4" },
      },
      {
        title: "操作",
        dataIndex: "action",
        width: 100,
        key: "_8",
        scopedSlots: { customRender: "_8" },
      },
    ],
  },
};
