/*
 * @Version: 2.0
 * @Autor: YangKX
 * @Date: 2023-05-05 17:25:32
 * @LastEditors: YangKX
 * @LastEditTime: 2023-05-16 13:19:03
 */
export default {
    customer: {
      columns: [
        {
          title: "合伙人方案",
          dataIndex: "scheme_name",
          key: "_0",
          width:'20%',
          ellipsis: true,
          scopedSlots: { customRender: "_0" },
        },
        {
          title: "预计分红",
          dataIndex: "predict_dividend_amount",
          width:'20%',
          key: "_1",
          ellipsis: true,
          scopedSlots: { customRender: "_1" },
        },
        {
          title: "统计月份",
          dataIndex: "dividend_month",
          width:'20%',
          key: "_2",
          ellipsis: true,
          scopedSlots: { customRender: "_2" },
        },
        {
          title: "分红订单号",
          dataIndex: "sn",
          width:'20%',
          key: "_4",
          ellipsis: true,
          scopedSlots: { customRender: "_4" },
        },
        {
          slots: { title: "_time" },
          // title: "汇入时间",
          width:'20%',
          dataIndex: "in_time",
          key: "_5",
          ellipsis: true,
          scopedSlots: { customRender: "_5" },
        }
      ],
    },
  };