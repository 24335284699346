<template>
  <div class="detail-box" v-if="showType">
    <!-- 面包屑 -->
    <div class="mrj-layout-tabs-custom flex align-item-center" style="z-index:1;">
      <div @click="changeRoute" class="mrj-layout-tabs-custom-index flex align-item-center cursor-pointer">
        <div type="left" class="leftarrow" :class="imgflag ? 'leftarrow_hover' : ''" @mouseenter="imgflag = true" @mouseleave="imgflag = false">
          <i class="meiye-icon meiye-fanghui"></i>
        </div>

        <span @mouseenter="imgflag = true" @mouseleave="imgflag = false">{{ $route.meta.title }}</span>
      </div>
      <span class="mrj-layout-tabs-custom-fenge">/</span>
      <span class="mrj-layout-tabs-custom-inner-page">查看门店合伙人账户</span>
    </div>
    <!-- 内容 -->
    <div class="height100 flex flex-column">
      <div class="detail-wrap">
        <div class="detail-wrap-top flex">
          <div class="detail-wrap-top_radio">
            <a-radio-group v-model="showStatus" class="lable_radiogroup" @change="statusChange">
              <a-radio-button style="width: 120px;text-align: center;" :value="1"> 进行中的方案 </a-radio-button>
              <a-radio-button style="width: 120px;text-align: center;" :value="2"> 历史方案 </a-radio-button>
            </a-radio-group>
          </div>
          <div class="detail-wrap-top_query">
            <a-select style="width: 373px;" @dropdownVisibleChange="isactive = !isactive" class="mgr-20" v-model="query.institution_id" placeholder="选择门店">
              <i slot="suffixIcon" class="meiye-icon meiye-xiala" :class="{ 't-c-g': isactive }" style="font-size:12px;color:#2D3835;background: @baseColor41;"></i>
              <a-select-option v-for="it in shopList" :key="it.id" :value="it.id">
                {{ it.name }}
              </a-select-option>
            </a-select>
            <a-select v-if="showStatus == 2" @dropdownVisibleChange="isactive = !isactive" v-model="query.partner_plan_id" @change="getInstitutionProvince" placeholder="全部方案">
              <a-select-option v-for="it in schemeList" :key="it.id" :value="it.id">
                {{ it.scheme_name }}
              </a-select-option>
              <i slot="suffixIcon" class="meiye-icon meiye-xiala" :class="{ 't-c-g': isactive }" style="font-size:12px;color:#2D3835;background: @baseColor41;"></i>
            </a-select>
          </div>
          <div style="width: 240px;"></div>
        </div>
        <div class="detail-wrap-info flex">
          <div class="detail-wrap-info_l flex">
            <div class="detail-wrap-info_l_item">
              <div class="l_item_text">合伙人</div>
              <div class="l_item_num">{{ statistics.partner || 0 }}</div>
            </div>
            <a-divider type="vertical" style="height: 37px; background-color: #D2E6E4;margin-top:10px" />
            <div class="detail-wrap-info_l_item">
              <div class="l_item_text">消费股</div>
              <div class="l_item_num">{{ statistics.subscribed_shares || 0 }}</div>
            </div>
            <a-divider type="vertical" style="height: 37px; background-color: #D2E6E4;margin-top:10px" />
            <div class="detail-wrap-info_l_item">
              <div class="l_item_text">认购金溢价</div>
              <div class="l_item_num" style="color: #00B2A8;">{{ statistics.subscription_fee_premium || 0 }}
              </div>
            </div>
          </div>
          <div class="detail-wrap-info_r flex">
            <!-- :class="tabIdx == 0 ? 'detail-wrap-info_r_itemC' : ''" -->
            <div class="detail-wrap-info_r_item cursor-pointer" @click="tabCut(0)">
              <div class="r_item_text">认购金</div>
              <div class="r_item_num" style="color: #00B2A8;">¥{{ statistics.subscription_fee || 0 }}</div>
            </div>
            <a-divider type="vertical" style="height: 37px; background-color: #D2E6E4;margin-top:26px" />
            <div class="detail-wrap-info_r_item cursor-pointer" @click="tabCut(1)" :class="tabIdx == 1 ? 'detail-wrap-info_r_itemC' : ''">
              <div class="r_item_text">实际消费
                <a-tooltip placement="top" overlayClassName="spec_tooltip">
                  <template slot="title">
                    实际消费为合伙人使用合伙人钱包支付，<br>
                    扣除溢价部分后换算出的实际支付金额
                  </template>
                  <a-icon :style="{ color: '#819190' }" type="info-circle" />
                </a-tooltip>
              </div>
              <div class="r_item_num" style="color: #00B2A8;">¥{{ statistics.actual_consumption || 0 }}</div>
            </div>
            <a-divider type="vertical" style="height: 37px; background-color: #D2E6E4;margin-top:26px" />
            <div class="detail-wrap-info_r_item cursor-pointer" @click="tabCut(2)" :class="tabIdx == 2 ? 'detail-wrap-info_r_itemC' : ''">
              <div class="r_item_text">已结算佣金</div>
              <div class="r_item_num" style="color: #00B2A8;">¥{{ statistics.commission_amount || 0 }}</div>
            </div>
            <a-divider type="vertical" style="height: 37px; background-color: #D2E6E4;margin-top:26px" />
            <div class="detail-wrap-info_r_item cursor-pointer" @click="tabCut(3)" :class="tabIdx == 3 ? 'detail-wrap-info_r_itemC' : ''">
              <div class="r_item_text">预存分红</div>
              <div class="r_item_num" style="color: #00B2A8;">¥{{ statistics.store_dividend_amount || 0 }}
              </div>
            </div>
            <a-divider type="vertical" style="height: 37px; background-color: #D2E6E4;margin-top:26px" />
            <div class="detail-wrap-info_r_item cursor-pointer" @click="tabCut(4)" :class="tabIdx == 4 ? 'detail-wrap-info_r_itemC' : ''">
              <div class="r_item_text">已结算分红</div>
              <div class="r_item_num" style="color: #00B2A8;">¥{{ statistics.dividend_amount || 0 }}</div>
            </div>
          </div>
        </div>
        <div class="detail-wrap-query">
          <a-row>
            <a-col :span="24" class="ant-input_1">
              <a-input v-model="query.search_key" style="width: 330px;" placeholder="合伙人姓名/电话" class="mgr-20" allowClear @change="getList">
                <i slot="prefix" class="meiye-icon meiye-sousuo"></i>
              </a-input>
            </a-col>
          </a-row>
        </div>
        <div class="mgb-20 mgt-12">
          <table-filter :flitList="flitList" :paddingTrue="true" :isAllLength="false" :isShowAll="true" @transferson="clearfliterSingle"></table-filter>
        </div>
        <a-table class="detailsTable flex-1" :scroll="{ x: '100%' }" @change="tableChanged" :pagination="table.pagination" row-key="member_id" :loading="table.loading" :columns="columns" :data-source="table.tableData">
          <template slot="_tityle">
            实际消费 <a-tooltip placement="top" overlayClassName="spec_tooltip">
              <template slot="title">
                实际消费为合伙人使用合伙人钱包支付，<br>
                扣除溢价部分后换算出的实际支付金额
              </template>
              <a-icon :style="{ color: '#819190' }" type="info-circle" />
            </a-tooltip>
          </template>
          <template slot="_time">
            <div class="cursor-pointer">
              <a-popover :title="null" v-model="visibleTime" trigger="click" placement="bottomLeft">
                <template slot="content">
                  <a-range-picker style="width:280px" v-model="dateRange" @change="onChangeTime" />
                </template>
                <span>
                  <i style="font-size:15px;color:#9EA6A4" class="meiye-icon meiye-riqi"></i>
                  <span v-if="tabIdx == 0"> 认购时间 </span>
                  <span v-else-if="tabIdx == 1"> 支付时间 </span>
                  <span v-else-if="tabIdx == 3"> 汇入时间 </span>
                  <span v-else> 结算时间 </span>
                  <i style="font-size:9px" class="meiye-icon meiye-xiala"></i>
                </span>
              </a-popover>
            </div>
          </template>
          <template slot="_0" slot-scope="text, record">
            <span class="cursor-pointer c-p-hover" v-if="record.scheme_name" style="color: #1BA196;" @click="toSchemeDetail(record, 1)">{{ record.scheme_name }}</span>
            <span v-else>--</span>
          </template>
          <template slot="_2" slot-scope="text, record">
            <span>{{ record.name }} {{ record.phone }}</span>
          </template>
          <template slot="_11" slot-scope="text, record">
            <span>{{ record.created_at ? record.created_at.slice(0, 10) : '--' }}~{{ record.identify_end_date
              ? record.identify_end_date.slice(0, 10) : '--' }}</span>
          </template>
        </a-table>
      </div>
    </div>
  </div>

  <div v-else>
    <AddDetail @toGoIndex="toGoIndex"></AddDetail>
  </div>
</template>
<script>
import config_subscription_fee from '../config_subscription_fee'
import AddDetail from '../add.vue'
import config_actual_consumption from '../config_actual_consumption'
import config_commission_amount from '../config_commission_amount'
import config_store_dividend_amount from '../config_store_dividend_amount'
import config_dividend_amount from '../config_dividend_amount'
import Msg from '@/components/mrj-menu/msg'
import { partnerAmountList, partnerUserStatistics, partnerIndexAll } from '@/api/partner'
import storage from 'store'
import TableFilter from '@/components/intimate/tableFilter/index'
export default {
  components: { AddDetail, TableFilter },
  data() {
    return {
      config_subscription_fee,
      config_actual_consumption,
      config_commission_amount,
      config_store_dividend_amount,
      config_dividend_amount,
      columns: config_subscription_fee.customer.columns,
      imgflag: false,
      visibleTime: false,
      showStatus: 1,
      showType: true,
      shopList: [],
      schemeList: [],
      dateRange: [],
      tabIdx: 0,
      tabText: "认购金",
      statistics: {},
      flitList: [
        { key: '数据', value: [], paramas: 'type' },
        { key: '时间', value: [], paramas: 'dateRange' },
      ], isactive: false,
      query: {
        per_page: 10,
        page: 1,
        start_time: '',
        search_key: '',
        end_time: '',
        institution_id: '',
        partner_plan_id: 0,
        type: 'subscription_fee',
      },
      table: {
        tableData: [],
        loading: false,
        pagination: {
          current: 1,
          total: 0,
          pageSize: 10, //每页中显示10条数据
          showSizeChanger: true,
          showQuickJumper: true,
        },
      }
    }
  },
  watch: {
    'query.institution_id': {
      handler(val) {
        if (val) {
          this.getPartnerIndexAll();
          this.statusChange()
        }
      },
      deep: true
    },
    'tabText': {
      handler(val) {
        console.log(val)
        this.flitList[0].value = [val];
      },
      deep: true
    }
  },
  created() {
    this.query.institution_id = this.$route.query.id;
    this.shopList = [{ id: this.$route.query.id, name: this.$route.query.shop_name }]
  },
  methods: {
    clearfliterSingle(e) {
      console.log(e);
      if (e) {
        // 单个清除 根据业务 去除 
        if (e.paramas == 'type') {
          this.query.status = 'subscription_fee'
        } else if (e.paramas == 'dateRange') {
          this.query.start_time = undefined;
          this.query.end_time = undefined;
          this.dateRange = []
        } else {
          this.query[e.paramas] = undefined
        }
        this.flitList.forEach(el => {
          if (el.paramas == e.paramas) {
            el.value = []
          }
        })
        this.getList()
      } else {
        // 全部清除
        this.clearfliterAll()
      }
    },
    // 清除 类别  分类 品牌  状态 恢复 初始值
    clearfliterAll() {
      // 清除分类
      this.flitList = [
        { key: '数据', value: [], paramas: 'type' },
        { key: '录入时间', value: [], paramas: 'dateRange' },
      ]
      this.onReset()
    },
    async getPartnerIndexAll() {
      this.schemeList = [
        {
          id: 'history',
          scheme_name: '全部历史方案'
        }]
      await partnerIndexAll({ institution_id: this.query.institution_id, type: 'history' }).then(res => {
        this.schemeList = this.schemeList.concat(res.data)
        if (this.schemeList.length && this.showStatus == 2) {
          this.query.partner_plan_id = this.schemeList[0].id;
        } else {
          this.query.partner_plan_id = 0;
        }
      })
    },
    getList() {
      let self = this
      self.table.loading = true
      const params = {
        page: self.query.page,
        per_page: self.query.per_page,
        search_key: self.query.search_key,
      }

      return partnerAmountList(self.query).then(res => {
        console.log(res.data, "data");
        const { data = [], total = 0 } = res.data
        self.table.tableData = data
        self.table.pagination = Object.assign({}, { pageSize: this.query.per_page, current: this.query.page }, {
          total: total
        })
      }).finally(r => {
        self.table.loading = false
      })
    },
    // 查看方案详情
    toSchemeDetail(e) {
      this.showType = false
      let item = {
        id: this.$route.query.id, partner_plan_id: this.$route.query.partner_plan_id, shop_name: this.$route.query.shop_name
      }
      this.$router.push({ path: 'partnerMenuUser', query: { type: 3, url: 2, item: item, id: e.partner_plan_id } })
    },
    toGoIndex(e) {
      this.showType = true
      this.$router.push({ path: 'partnerMenuUser', query: { id: e.id, partner_plan_id: e.partner_plan_id, shop_name: e.shop_name } })
    },
    getStatistics() {
      let self = this
      const params = {
        institution_id: self.query.institution_id,
        partner_plan_id: self.query.partner_plan_id,
      }
      partnerUserStatistics(params).then(res => {
        this.statistics = res.data
      })
    },
    statusChange(e) {
      this.showStatus == 1 ? this.query.partner_plan_id = 0 : this.query.partner_plan_id = 'history'
      this.getPartnerIndexAll()
      this.getList()
      this.getStatistics()
    },
    onReset() {
      this.query.per_page = 10;
      this.query.page = 1;
      this.tabIdx = 0;
      this.query.type = "subscription_fee"
      this.query.search_key = undefined;
      this.query.start_time = undefined;
      this.query.end_time = undefined;
      this.dateRange = []
      this.getList()
    },
    getInstitutionProvince(e) {
      this.getList()
      this.getStatistics()
    },
    onChangeTime(rec, time) {
      this.visibleTime = false;
      this.query.start_time = rec[0] ? rec[0].format('YYYY-MM-DD') : '';
      this.query.end_time = rec[1] ? rec[1].format('YYYY-MM-DD') : '';
      this.flitList[1].value = [this.query.start_time + '~' + this.query.end_time];
      this.getList()
    },
    tableChanged(pagination, filters, sorter, { currentDataSource }) {
      const { current, pageSize } = pagination
      const { field, order } = sorter
      this.query = Object.assign(this.query, { per_page: Number(pageSize), page: Number(current) })
      this.getList()
    },
    tabCut(e) {
      this.tabIdx = e
      switch (e) {
        case 0:
          this.tabText = "认购金"
          this.columns = config_subscription_fee.customer.columns
          this.query.type = "subscription_fee"
          break;
        case 1:
          this.tabText = "实际消费"
          this.columns = config_actual_consumption.customer.columns
          this.query.type = "actual_consumption"
          break;
        case 2:
          this.tabText = "已结算佣金"
          this.columns = config_commission_amount.customer.columns
          this.query.type = "commission_amount"
          break;
        case 3:
          this.tabText = "预存分红"
          this.columns = config_store_dividend_amount.customer.columns
          this.query.type = "store_dividend_amount"
          break;
        case 4:
          this.tabText = "已结算分红"
          this.columns = config_dividend_amount.customer.columns
          this.query.type = "dividend_amount"
          break;
      }
      this.getList()
    },
    changeRoute() {
      console.log('this.$route.fullpath :>> ', this.$route);
      this.$router.push({ path: this.$route.fullpath });
      let newobject = storage.get('seconderyMenuObj')
      Msg.$emit('getpathname', newobject)
    },
  }
}
</script>
<style scoped lang="less">
.detail-box {
  .detail-wrap {
    padding: 24px 24px 0 24px;
    margin: 18px;
    height: calc(100vh - 138px);
    background: #fff;
    overflow-y: auto;

    .detail-wrap-top {
      justify-content: space-between;
    }

    .detail-wrap-info {
      margin-top: 24px;
      margin-bottom: 24px;
      justify-content: space-between;

      .detail-wrap-info_l {
        width: 35%;
        height: 116px;
        background: #fafcfc;
        border-radius: 8px;
        border: 1px solid #d2e6e4;
        justify-content: space-around;
        text-align: center;
        align-items: center;
        .detail-wrap-info_l_item {
          display: inline-block;
          margin-top: 4px;
          width: 32%;
          .l_item_text {
            font-size: 14px;
            font-weight: 400;
            color: #4d5e59;
          }

          .l_item_num {
            font-size: 22px;
            font-family: DINAlternate-Bold, DINAlternate;
            font-weight: bold;
            color: #131010;
          }
        }
      }

      .detail-wrap-info_r {
        width: 65%;
        height: 116px;
        padding: 14px 8px;
        border-top: 1px solid #d2e6e4;
        border-bottom: 1px solid #d2e6e4;
        justify-content: inherit;
        text-align: center;
        margin-left: 20px;

        .detail-wrap-info_r_item {
          padding: 17px 10px;
          min-width: 142px;

          .r_item_text {
            font-size: 14px;
            font-weight: 400;
            color: #4d5e59;
          }

          .r_item_num {
            font-size: 22px;
            font-family: DINAlternate-Bold, DINAlternate;
            font-weight: bold;
            color: #131010;
          }
        }

        .detail-wrap-info_r_itemC {
          background: #f4f9f8;
          border-radius: 8px;
        }
      }
    }
  }
}
.xing {
  font-size: 14px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #f35e67;
}

/deep/.ant-checkbox-wrapper + .ant-checkbox-wrapper {
  margin-left: 0;
}
</style>